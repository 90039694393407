export const environment: any = {
  production: true,
  api_domain: "https://dev.radrservices.spectrumreach.io",
  sentry_dsn: "https://0f8c2f987d2c491ea76e48613ca7a37e@sentry.srdata.io/5",
  app_env: "dev",
  keycloak_group_ids: {
    admin: "4e4ec2c6-beed-4c90-8d69-b5d1a7b5cfdb",
    editor: "c16c2b77-19c3-4e0f-8bcb-eba52fdaae99",
    readonly: "2fac7b15-ce7f-4ebb-a2bb-cc0ba1bae54d",
    limitedaccess: "6deccd58-b5fa-48ff-885b-53cc8c166cc1"
  },
  // version is automatically replaced by the CI build, do not adjust manually
  version: "pipelines/5480427-"
};
